import request from '@/utils/request'
import {parseStrEmpty} from "@/utils/ruoyi";

// 查询用户等级列表
export function listUserLevel(query) {
    return request({
        url: '/v1/userLevel/list',
        method: 'get',
        params: query
    })
}
//用户会员过期时间
export function userExpireTime(levelId) {
    return request({
        url: '/system/user/expireTime/' + levelId,
        method: 'get'
    })
}
// 新增用户
export function addUser(user) {
    return request({
      url: '/v1/user/app/userAdd',
      method: 'post',
      data: user
    })
}
//pdf
export function htmltopdf(data) {
  // let param = new URLSearchParams()
  // param.append('htmlContent', data.htmlContent)
  // param.append('pdfCustomId', data.pdfCustomId)
  return request({
    url: process.env.VUE_APP_BASE_ADDRESS,
    method: 'post',
    data: data,
    // responseType: 'blob'
    responseType: 'arraybuffer'
  })
}
// 查询部门下拉树结构
export function treeselect() {
  return request({
    url: '/system/dept/treeselect',
    method: 'get'
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/system/user/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 查询部门详细
export function getDept(deptId) {
  return request({
    url: '/system/dept/' + deptId,
    method: 'get'
  })
}

//兴趣爱好列表
export function getDisplayList(){
  return request({
    url:'/v1/interest/displayList',
    method:'get'
  })
}
