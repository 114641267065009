<template>
  <div class="e-form-view-box">
    <div class="top-con">
      <!-- <img src="@/assets/whale-club-logo.png" style="height: 60px" alt="" /> -->
      <img :src="dataImfURL" style="height: 65px;margin: 2px 0;" alt="" />
      <!-- <div v-if="footerBtn" @click="LogOutFun" class="log-out"><i class="el-icon-switch-button"></i>&nbsp;Log out</div> -->
    </div>
    <el-form
      ref="form"
      :model="form"
      label-width="260px"
      class="el-form-content"
    >
      <div class="sponsor-top-box pdf-page-break" style="display: block !important;">
        <h3 style="text-align: center;" v-if="form.accountType">APPLICATION FORM – INDIVIDUAL MEMBERSHIP</h3>
          <h3 style="text-align: center;" v-else>APPLICATION FORM – CORPORATE MEMBERSHIP</h3>
        <el-col :span="24" class="acco-com-lst">
          <el-form-item label="* Account type:">
            <div class="con-div-sty" v-if="form" style="width: 150px;">
              {{form.membershipLevel==2?'Individual':'Corporate'}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="acco-com-lst" v-if="form.membershipLevel==3">
          <el-form-item label="* Company Name:" >
            <div class="con-div-sty" v-if="form.userDetails" style="width: 150px;">
              {{form.userDetails.companyName}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="acco-com-lst" v-if="form.membershipLevel==3">
          <el-form-item label="* Type:" prop="postIds">
            <div class="con-div-sty" v-if="form" style="width: 150px;">
              {{form.postTypeName}}
            </div>
          </el-form-item>
        </el-col>
        <div style="margin-bottom: 15px">
          <div>* Membership Number:</div>
          <div class="con-div-sty" style="line-height: 35px;">
            {{ form.displayId }}
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <div v-show="form.accountType"> * I hereby apply for Individual Membership Recommended to WHALE CLUB by (Member Sponsor):</div>
          <div v-show="!form.accountType">* I hereby apply for Corporate Membership Recommended to WHALE CLUB by (Member Sponsor):</div>
          <div class="con-div-sty" style="line-height: 35px;" v-if="form.userDetails">
            {{ form.userDetails.recommendedBy }}
          </div>
        </div>
        <div style="margin-bottom:10px;">* Require Field</div>
      </div>
      <!-- 個人 註冊人的信息 -->
      <el-row class="member-box pdf-page-break" v-if="form.accountType">
        <div class="member-left">
          <div class="tit">MEMBER</div>
          <div class="perview-head-portrait-img">
            <!-- <img v-if="applicantPhotoBase64" :src="applicantPhotoBase64" alt="" /> -->
            <img v-if="form.applicantPhotoBase64" :src="form.applicantPhotoBase64" alt="" />
          </div>
        </div>
        <div class="member-right">
          <!-- <el-col :span="24">
            <el-form-item  label="* User Name:"  >
              <div class="con-div-sty" v-if="form">
                {{form.userName}}
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item  label="Wallet Address:" >
              <div class="con-div-sty" v-if="form">
                {{form.walletAddress}}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="NFT Token ID:" >
            <div class="con-div-sty" v-if="form">
              {{form.nftTokenId}}
            </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Courtesy Title:" >
            <div class="con-div-sty" v-if="form">
              {{form.userDetails.title}}
            </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Name in Full (English):">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.fullNameEn }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Name in Full (Chinese):">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.fullNameCn }}
              </div>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="Preferred Name:">
              <div class="con-div-sty" v-if="form">
                {{ form.nickName }}
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="* Gender:">
              <div class="con-div-sty" v-if="form">
                {{ form.sex==0?'Male':'Female' }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Date of Birth (dd/mm/yy):">
              <div class="con-div-sty" v-if="form"> 
                {{ form.birthday }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* HKID/Passport No.:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.hkidPassportNumber }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Citizenship:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.citizenship }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Residential Address:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.homeAddress }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Area Code" prop="phoneArea">
              <div class="con-div-sty">{{form.phoneArea}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Contact Number:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.contactNumber }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Email Address:">
              <div class="con-div-sty" v-if="form">
                {{ form.email }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Marital Status:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.maritalStatus==0?'Not married':'Married' }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Name of Spouse:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.nameOfSpouse }}
              </div>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
      <!-- COMPANY 公司 -->
      <el-row class="company-box pdf-page-break">
        <div class="company-left">
          <div class="tit">COMPANY INFORMATION</div>
        </div>
        <div class="company-right">
          <el-col :span="24">
            <el-form-item label="* Company Name:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.companyName }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Nature of Business:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.natureOfBusiness }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Occupation or Position Held:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.occupation }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Business Address:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.businessAddress }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Office Phone:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.officePhone }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Office Fax:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.officeFax }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Secretary Name:">
              <div class="con-div-sty" v-if="form">
                {{
                  form.userDetails.secretaryAssistantName
                }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Secretary Contact Number:">
              <div class="con-div-sty" v-if="form">
                {{
                  form.userDetails
                    .secretaryAssistantContactNumber
                }}
              </div>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
      <el-row class="member-box pdf-page-break" v-if="!form.accountType">
        <div class="member-left">
          <div class="tit">NOMINEE</div>
          <div class="perview-head-portrait-img">
            <!-- <img v-if="applicantPhotoBase64" :src="applicantPhotoBase64" alt="" /> -->
            <img v-if="form.applicantPhotoBase64" :src="form.applicantPhotoBase64" alt="" />
          </div>
        </div>
        <div class="member-right">
          <!-- <el-col :span="24">
            <el-form-item  label="* User Name:"  >
              <div class="con-div-sty" v-if="form">
                {{form.userName}}
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item  label="Wallet Address:" >
              <div class="con-div-sty" v-if="form">
                {{form.walletAddress}}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="NFT Token ID:" >
            <div class="con-div-sty" v-if="form">
              {{form.nftTokenId}}
            </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Courtesy Title:" >
            <div class="con-div-sty" v-if="form">
              {{form.userDetails.title}}
            </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Name in Full (English):">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.fullNameEn }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Name in Full (Chinese):">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.fullNameCn }}
              </div>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="Preferred Name:">
              <div class="con-div-sty" v-if="form">
                {{ form.nickName }}
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="* Gender:">
              <div class="con-div-sty" v-if="form">
                {{ form.sex==0?'Male':'Female' }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Date of Birth (dd/mm/yy):">
              <div class="con-div-sty" v-if="form"> 
                {{ form.birthday }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* HKID/Passport No.:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.hkidPassportNumber }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Citizenship:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.citizenship }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Residential Address:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.homeAddress }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Area Code" prop="phoneArea">
              <div class="con-div-sty">{{form.phoneArea}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Contact Number:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.contactNumber }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="* Email Address:">
              <div class="con-div-sty" v-if="form">
                {{ form.email }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Marital Status:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.maritalStatus==0?'Not married':'Married' }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Name of Spouse:">
              <div class="con-div-sty" v-if="form">
                {{ form.userDetails.nameOfSpouse }}
              </div>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
      <!-- spouse -->
      <el-row class="spouse-box pdf-page-break" v-show="form.accountType">
        <div class="spouse-left">
          <div class="tit">SPOUSE</div>
          <div class="perview-head-portrait-img">
            <!-- <img v-if="spousePhotoBase64" :src="spousePhotoBase64" alt="" /> -->
            <img v-if="form.spousePhotoBase64" :src="form.spousePhotoBase64" alt="" />
          </div>
        </div>
        <div class="spouse-right">
          <el-col :span="24">
            <el-form-item label="Name in Full (English):">
              <div class="con-div-sty" v-if="form">
                {{
                  form.userExtraInfo
                    .spouseNameInFullEnglish
                }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Name in Full (Chinese):">
              <div class="con-div-sty" v-if="form">
                {{
                  form.userExtraInfo
                    .spouseNameInFullChinese
                }}
              </div>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="Preferred Name:">
              <div class="con-div-sty" v-if="form">
                {{ form.userExtraInfo.preferredname }}
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="Date of Birth (dd/mm/yy):">
              <div class="con-div-sty" v-if="form">
                {{ form.userExtraInfo.birthday }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="HKID/Passport No.:">
              <div class="con-div-sty" v-if="form">
                {{ form.userExtraInfo.hkidPassportNo }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Citizenship:">
              <div class="con-div-sty" v-if="form">
                {{ form.userExtraInfo.citizenship }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Residential Address:">
              <div class="con-div-sty" v-if="form">
                {{
                  form.userExtraInfo.residentialAddress
                }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Contact Number:">
              <div class="con-div-sty" v-if="form">
                {{ form.userExtraInfo.contactNumber }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Email Address:">
              <div class="con-div-sty" v-if="form">
                {{ form.userExtraInfo.emailAddress }}
              </div>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
      <!-- INTEREST -->
      <el-row class="interest-box pdf-page-break">
        <div class="interest-left">
          <div class="tit">INTEREST</div>
        </div>
        <div class="interest-right">
          <el-col :span="24">
            <div>
              Share your interest with us for the latest WHALE CLUB news &
              promotions
            </div>
          </el-col>
          <div class="options-con">
            <el-checkbox-group
              v-model="form.userInterests"
            >
              <el-checkbox 
                v-for="item in interestsOptions"
                :label="item.interestEnglish==null?item.interestChinese:item.interestEnglish"
                :key="item.id"
                disabled
                >{{ item.interestEnglish==null?item.interestChinese:item.interestEnglish }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px">
            <span style="color: #606266; font-size: 14px">Other：</span>
            <div
              class="con-div-sty"
              v-if="form"
              style="width:25%; line-height: 30px"
            >
              {{ form.otherInterest }}
            </div>
          </div>
        </div>
      </el-row>
      <div class="agree-member-con pdf-page-break" v-if="1 != 1">
        <div style="font-weight: 600">
          I would like to become an Individual Member of WHALE CLUB and agree
          that –
        </div>
        <ul>
          <li>
            An Entry Fee currently at HKD$50,000 shall be made payable to WHALE
            CLUB LIMITED
          </li>
          <li>
            A MonthIy Fee currently at HKD$1,500 will be charged at the first
            day of each month through an autopay system
          </li>
          <li>
            The Membership is non-transferrable but resellable only through the
            Board
          </li>
          <li>
            The Entry Fee and Annual Fee, once paid, are not refundable in any
            circumstances
          </li>
          <li>
            The location of WHALE CLUB will be at 1F @ 29 LYNDHURST TERRACE,
            CENTRAL, HONG KONG
          </li>
        </ul>
      </div>
      <div class="mem-wh-cl-box pdf-page-break">
        <div class="tit">MEMBERSHIP AT WHALE CLUB</div>
        <div>
          <div style="font-weight: 600">INDIVIDUAL MEMBERSHIP</div>
          This Membership is open to individuals who have been invited and
          referred by an existing member of WHALE CLUB One-time Entry Fee in the
          amount of HKD$50,000 to be paid at the time of application A Monthly
          Fee in the amount of HKD$1,500 to be paid at the beginning of each
          month through an autopay system A monthly credit rebate of 500 WHALE
          POINTS which is equivalent to HKD$500 will be added to member’s WHALE
          CLUB APP Wallet or in a form of e-voucher for any consumptions within
          the WHALE CLUB
          <div style="font-weight: 600; margin-top: 20px">
            PRE-SALES WELCOMING PROMOTION FOR INDIVIDUAL MEMBERSHIP
          </div>
          First 100 WHALE CLUB new join members are entitled for 20,000 WHALE
          CLUB POINTS which is equivalent to HKD$20,000 for any consumptions
          within the WHALE CLUB The club points are valid for 6 months from the
          day of enrolment The 101 – 200 WHALE CLUB new join members are
          entitled for 10,000 WHALE CLUB POINTS which is equivalent to
          HKD$10,000 for any consumptions within the WHALE CLUB The club points
          are valid for 6 months from the day of enrolment
          <div style="font-weight: 600; margin-top: 20px">
            CORPORATE MEMBERSHIP
          </div>
          This Membership is open to corporations or partnerships which have
          been invited and referred by an existing member of WHALE CLUB One-time
          Entry Fee in the amount of HKD$250,000 to be paid at the time of
          application for three nominees Each corporate member is entitled to
          switch its nominee once every calendar year, subject to an
          Administrative Surcharge in the amount of HKD$5,000 per amendment
          <div style="font-weight: 600; margin-top: 20px">REFUND POLICIES</div>
          Any Entry Fee and Monthly Fee, once paid, are not refundable in any
          circumstances
          <div style="font-weight: 600; margin-top: 20px">ADDITIONAL NOTES</div>
          Each application for Membership to WHALE CLUB is subject to acceptance
          by the Admissions Team WHALE CLUB reserves the right to check
          references and verify employment The stated fees/dues are applicable
          currently and are subject to change with or without notice
        </div>
      </div>
      <!-- STORAGE DISCLAIMER -->
      <div class="storage-disclaimer-box pdf-page-break">
        <div class="tit">STORAGE DISCLAIMER</div>
        <div>
          Wine and cigar storage are provided within the premises of WHALE CLUB by the management of the Club, or herein referred to as “The Club” (which expression shall include its officers, employees and agents) entirely on a complimentary basis. While the Club endeavours to keep the items stored in appropriate and monitored environments with reasonable level of security, I, on behalf of myself, hereby acknowledge and agree that the Club, to the fullest extent permitted under law, does not accept and specifically disclaims any duty to prevent and accordingly also any liability for any property damage to all Relevant Persons and their respective properties. In no event will the Club be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of profits arising out of, or in connection with, the use of such storage services. Every effort is made to keep the storage service running smoothly, yet the Club cannot and will not accept liability or responsibility for damage caused or ultimately attributable to causes beyond the control of the Club.
        </div>
        <div class="canvaspanel" style="display: none;">
          <div class="canvasborder">
            <div style="width: 200px;">Member’s Signature:</div>
            <div style="border: 1px solid #c7c7c7;padding: 10px;width: 500px;height: 120px;">
              <img :src="form.userStorageDisclaimer.signature" alt="">
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;display: none;">
          <span>Date：</span>
          <span style="border-bottom: 1px solid #c7c7c7;">{{form.signatureDate}}</span>
        </div>
      </div>
      <div class="declartion-box pdf-page-break">
        <div class="tit">DECLARATION OF APPLICANT</div>
        <div>
          <p>
            I, on behalf of myself, hereby acknowledge and agree that WHALE CLUB or herein referred to as “The Club” (which expression shall include its officers, employees and agents), to the fullest extent permitted under law, does not accept and specifically disclaims any duty to prevent and accordingly also any liability for personal injury and/or property damage to all Relevant Persons and their respective properties.
          </p>
          <p>
            Without prejudice to the above, while The Club does endeavour at all times to provide what The Club believes in good faith to be a reasonable level of security, The Club cannot and will not accept liability or responsibility for injuries or damage caused or ultimately attributable to causes beyond the control of The Club. The Club specifically disclaims any liability for personal injury and/or property damage to all relevant persons and their respective properties caused or ultimately attributable to causes beyond the control of The Club or for any disruption to access to The Club or its premises or facilities or any closure of same or to maintain daily enjoyment within The Club, specifically due to, including but not limited to, licensing issues or the fact that The Club has been fully occupied in terms of capacity.
          </p>
          <p>
            If I, or any other relevant persons, should require medical attention at any time while on The Club property or participating in any Club events/activities, I also agree and acknowledge that should we at any time suffer and sustain any incapacity so as to make it impossible or impractical to secure consent (informed or otherwise) to any medical assistance or treatment, any employee of The Club shall in such case be authorized to call for medical attention or help me as he/she deems fit. I also accept that in making such a call he/she does so as a concerned layperson and not as an expert and that in any event I, or any persons included under my Membership will indemnify and keep The Club indemnified against any and all costs, expenses, loss or claims that it may suffer arising from or in relation to medical assistance or treatment.
          </p>
          <p>
            I also, hereby confirm that the information contained in this form is true and accurate. As a Member, I agree to comply with and be bound by THE WHALE CLUB CLUB RULES, which shall be attached with this application form. Failure to do so may result in the termination of the membership.
          </p>
          <p>
            I also acknowledge that my likeness and those of any and all other relevant persons may be reproduced on or in The Club premises, publications, and/or marketing materials without prior notification or consent.
          </p>
          <p>
            I also agree to ensure that all other relevant persons are aware of the acknowledgement and agreement made on each of their behalves and that their acceptances of the same is a condition to their being allowed under cover of my Membership to have access to The Club’s premises and/or facilities.
          </p>
        </div>
        <div class="canvaspanel">
          <div class="canvasborder">
            <div style="width: 200px">* Applicant’s Signature:</div>
            <div style="border: 1px solid #c7c7c7; padding: 10px;width: 500px;height: 120px;">
              <img :src="form.signature" alt="" />
            </div>
          </div>
        </div>
        <div style="margin-top: 10px">
          <span>Date：</span>
          <span style="border-bottom: 1px solid #c7c7c7;padding: 0 20px;">
            {{form.signatureDate}}
          </span>
        </div>
      </div>
      <!-- credit card -->
      <div class="credit-card-box" style="display: none;">
        <div class="tit">CREDIT CARD AUTHORIZATION FORM</div>
        <div>
          <p>
            This payment authorization is only for the cancellation fee based on the WHALE CLUB, hereinafter referred to as the “Club”, cancellation policy. 
          </p>
          <p>
            Any cancellation or rescheduling of your booking must be made at least forty-eight (48) hours prior to your reservation time to avoid a cancellation fee. Please note that the same policy applies to any COVID-related cancellations.
          </p>
          <p>
            Cancellation Policy for Gallery Table booking
            A cancellation fee in the amount of $500 per person will be applied in the case of cancellation if such request is made within forty-eight (48) hours prior to your reservation time. All cancellation fees, if applicable, will be deducted directly from your member’s wallet or your credit card on record without prior notice. If there is insufficient balance in your member’s wallet or your credit card transaction is being denied, the club reserved the rights to request payment from you in another form of payment.
          </p>
          <p>
            Cancellation Policy for Private Room booking
            A cancellation fee in the amount of the minimum charge of the room will be applied in the case of cancellation if such request is made within forty-eight (48) hours prior to your reservation time. All cancellation fees, if applicable, will be deducted directly from your member’s wallet or your credit card on record without prior notice. If there is insufficient balance in your member’s wallet or your credit card transaction is being denied, the club reserved the rights to request payment from you in another form of payment.
          </p>
          <p>
            For pre-ordered special customized menus, a cancellation fee in the amount of 100% of the original total quoted price will be applied in the case of cancellation if such request is made within seven (7) days prior to your reservation time. In the case of a reduction in the final number of guests, a maximum of 20% of the originally committed number of guests is allowed. For a reduction in the final number of guests over 20%, 80% of the original quoted price will still be applied.
          </p>
          <p>
            Please sign and e nsure that all fields have been filled in correctly. By signing this form, you give us permission to debit your account for the amount accrued by any booking cancellation(s). All information will remain confidential.
          </p>
        </div>
        <div class="con">
          <p>CREDIT CARD INFORMATION</p>
          <div class="type">
            <el-radio-group v-model="form.userCreditCard.cardType">
              <el-radio label="VISA"></el-radio>
              <el-radio label="MASTERCARD"></el-radio>
              <el-radio label="AMEX"></el-radio>
              <el-radio label="UNION PAY"></el-radio>
              <br/>
              <el-radio label="OTHER" style="margin-top: 10px;">
                <span>OTHER:</span>
                <span class="con-div-sty" v-if="form" style="width: 150px;">{{form.userCreditCard.cardTypeOther}}</span>
              </el-radio>
              
            </el-radio-group>
            <div style="display: flex;margin-top: 10px;align-items: center;">
              <div style="flex: none;">Cardholder’s Name (as shown on the card):</div>
              <div class="con-div-sty" v-if="form" style="width: 150px;line-height: 30px;">{{form.userCreditCard.cardHolderName}}</div>
            </div>
            <div style="display: flex;margin-top: 10px;align-items: center;">
              <div style="flex: none;">Card Number:</div>
              <div class="con-div-sty" v-if="form" style="width: 150px;line-height: 30px;">{{form.userCreditCard.cardNumber}}</div>
            </div>
            <div style="display: flex;margin-top: 10px;align-items: center;">
              Expiration Date (mm/yy):
              <div class="con-div-sty" v-if="form" style="width: 150px;line-height: 30px;">{{form.userCreditCard.expirationDate}}</div>
            </div>
            <div style="display: flex;margin-top: 10px;align-items: center;">
              Card Identification Number (3-digit number at the back of the card):
              <div class="con-div-sty" v-if="form" style="width: 150px;line-height: 30px;">{{form.userCreditCard.idCardBack3}}</div>
            </div>
            <div style="margin-top: 10px;">
              <span>I,</span>
              <span class="con-div-sty">{{form.userCreditCard.readName}}</span>,
              authorize WHALE CLUB LIMITED to charge my credit card above based on the abovementioned agreed cancellation policy.
              I agree that no prior notification will be provided for any cancellation fee collected. 
              I declare that the information provided in this application form is correct. I understand that my information will be saved to file for future transactions on my account.
            </div>
          </div>
          <div class="canvaspanel">
            <div class="canvasborder">
              <div style="width: 200px;"><span style="color: #F56C6C;"></span>Member Signature:</div>
              <div style="border: 1px solid #c7c7c7;padding: 10px;width: 500px;height: 120px;">
                 <img :src="form.userCreditCard.signature" alt="">
              </div>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span>Date：</span>
            <span style="border-bottom: 1px solid #c7c7c7;">{{form.signatureDate}}</span>
          </div>
        </div>
      </div>

    </el-form>
    <div slot="footer" class="submit-form" v-if="1 != 1">
      <el-button @click="cancelFun">go back</el-button>
      <!-- <el-button type="success" @click="confirm">
        <i class="el-icon-download"></i> download PDF 
      </el-button> -->
      <el-button type="success" @click="confirm">  Submit  </el-button>
    </div>
  </div>
</template>
    
<script>
import { htmltopdf,addUser } from "@/api/index";
import { removeToken } from '@/utils/auth'


export default {
  props:{
    userInfo:{
      type:Object
    },
    cities:{
      type:Array
    }
    // applicantPhotoBase64:{
    //   type: String,
    //   default: "",
    // },
    // spousePhotoBase64:{
    //   type: String,
    //   default: "",
    // }
  },
  data() {
    return {
      form: {
        userDetails:{},
        userExtraInfo:{},
        userStorageDisclaimer:{},
        userCreditCard:{}
      },
      applicantPhoto:'',
      spousePhoto:'',
      signatureTitle: "手写签名",
      lineWidth: 10,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      userInterests: [],
      signatureDate: null,
      pdfCustomId: null,
      footerBtn:true,
      dataImfURL:'',
      htmlCon:'',
      interestsOptions:[]
    };
  },
  mounted() {
    this.form=this.userInfo
    this.interestsOptions=this.cities
  },
  created() {
    // this.form = JSON.parse(this.$route.query.formData);
    // console.log(this.form);
    this.imageUrlToBase64()

    // this.footerBtn=false
    // setTimeout(()=>{
    //   this.htmlCon = document.documentElement.outerHTML;
    //   console.log(this.htmlCon)
    //   this.htmltopdfFun()
    // },2000)
  },
  methods: {
    //提交按钮
    confirm() {
      Promise.all([
        this.footerBtn=false,
        this.$emit('eformBox',false),
        this.$emit('dialogVisibleEform',false)
      ]).then(() =>{
        setTimeout(()=>{
          this.htmlCon = document.documentElement.outerHTML;
          addUser(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({ message: res.msg, type: "success",duration:1500 });
              this.htmltopdfFun()
            } else {
              // this.user.postIds=2
              this.$message.error("System error");
            }
          });
          this.$emit('eformBox',true)
        },500)
        
      })
    },
    htmltopdfFun(){
      this.footerBtn=false
      // console.log(document.documentElement.outerHTML)
      const loading = this.$loading({
        lock: true,
        text: 'The pdf is being generated...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.4)'
      });
      
      setTimeout(()=>{
        htmltopdf({ pdfCustomId: this.form.pdfCustomId, htmlContent: this.htmlCon }).then(
          (blob) => {
            let binaryData = [];
            binaryData.push(blob);
            let link = window.URL.createObjectURL(
              new Blob(binaryData, { type: "application/pdf; charset=utf-8" })
            );
            window.open(link, "", "width = 800,height = 1000");
            loading.close()
          }).catch(err => {
            console.log(err)
            loading.close()
            this.$alert('Pdf generation failed ===》 '+err,'Tips', {confirmButtonText: 'ok',type: 'warning'}).then(() => {
              this.$router.push({ path: "/eForm" });
            })
          })
        this.footerBtn=true
      },200)
    },
    //取消 返回
    cancelFun() {
      this.$router.push({ path: "/eForm" });
    },
    //本地图片转Base64
    imageUrlToBase64() {
      let that=this
			//一定要设置为let，不然图片不显示
			let image = new Image();
			//解决跨域问题
			image.setAttribute('crossOrigin', 'anonymous');
			let imageUrl = require("../assets/whale-c-logo.png")
			image.src = imageUrl
			//image.onload为异步加载
			image.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				var context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, image.width, image.height);
				var quality = 0.8;
				//这里的dataurl就是base64类型
				that.dataImfURL = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！		

			}
		},
    // 退出登录
    LogOutFun(){
      removeToken()
      this.$router.push({path:'/login'})
    }
  },
};
</script>