<template>
  <div class="e-form-box">
    <div v-show="isEformBox">
      <div class="top-con">
        <img src="@/assets/whale-club-logo.png" style="height: 45px;margin: 8px 0;" alt="">
        <div @click="LogOutFun" class="log-out"><i class="el-icon-switch-button"></i>&nbsp;Log out</div>
      </div>
      <el-form
        ref="user"
        :model="user"
        :rules="rules"
        label-width="260px"
        class="el-form-content"
      >
        <!-- sponsor-top-box -->
        <div class="sponsor-top-box" style="display: block !important;border-bottom: 2px solid #000;" >
          <h3 style="text-align: center;" v-if="user.accountType">APPLICATION FORM – INDIVIDUAL MEMBERSHIP</h3>
          <h3 style="text-align: center;" v-else>APPLICATION FORM – CORPORATE MEMBERSHIP</h3>
          <el-col :span="24" class="acco-com-lst">
            <el-form-item label="Account type:" prop="membershipLevel">
              <el-select style="width: 220px;"
                v-model="user.membershipLevel"
                placeholder="Account type"
                @change="setDeadline(user.membershipLevel)"
              >
                <el-option
                  v-for="level in userLevelList"
                  :key="level.levelId"
                  :label="level.levelName"
                  :value="level.levelId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="acco-com-lst" v-if="user.membershipLevel==3">
            <el-form-item label="Company Name:" prop="userDetails.companyName" ref="elAutocomplete">
              <el-autocomplete
                style="width: 220px;"
                v-model="user.userDetails.companyName"
                :fetch-suggestions="querySearchAsync"
                placeholder="Please enter"
                @select="handleSelect"
                @change="companyNameIntFun(user.userDetails.companyName)"
                @blur="companyNameIntBlur(user.userDetails.companyName)"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="acco-com-lst" v-if="user.membershipLevel==3">
            <el-form-item label="Type:" prop="postIds">
              <el-select style="width: 220px;" v-model="user.postIds" :disabled="user.userDetails.companyName==''?true:false" placeholder="Please select" @change="postIdsTypeSelect">
                <el-option
                  v-for="item in postOptions"
                  :key="item.postId"
                  :label="item.postName"
                  :value="item.postId"
                  :disabled="(item.postId==1 && disabledSelectMaster==true && isExistCompanyName) || (item.postId==2 && !isExistCompanyName)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div>
            <div style="margin-bottom: 15px;">
              <div><span style="color: #F56C6C;">* </span>Membership Number:</div>
              <el-input
                v-model="user.displayId"
                placeholder="Please enter"
                :maxlength="maximumLengthDis"
              />
            </div>
            <div style="margin-bottom: 15px;">
              <div v-show="user.accountType"><span style="color: #F56C6C;">* </span>I hereby apply for Individual Membership Recommended to WHALE  CLUB by (Member Sponsor):</div>
              <div v-show="!user.accountType"><span style="color: #F56C6C;">* </span>I hereby apply for Corporate Membership Recommended to WHALE  CLUB by (Member Sponsor):</div>
              <el-input
                v-model="user.userDetails.recommendedBy"
                placeholder="Please enter"
                maxlength="30"
                minlength="1"
              />
            </div>
            <div><span style="color: #fb0000;">* </span>Require Field</div>
          </div>
        </div>
        <!-- 个人账户与公司账户 -->
        <div class="individual-cont">
          <!-- member-box -->
          <el-row class="member-box" v-show="user.accountType">
            <div class="member-left">
              <div class="tit">MEMBER</div>
              <!-- 图片上传 -->
              <div class="img-upl">
                <el-upload
                  :headers="headers"
                  class="avatar-uploader"
                  accept="image/*"
                  :action="uploadImgUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="applicantPhotoBlob" :src="applicantPhotoBlob" class="avatar">
                  <i v-else class="el-icon-camera avatar-uploader-icon"></i>
                </el-upload>
                <p v-if="applicantPhotoBlob" style="width: 155px;text-align: center;font-size: 20px;color: gray;" class="el-icon-close" @click="applicantPhotoDel"></p>
                <p style="width: 155px;text-align: center;color: #878889;">Profile picture</p>
              </div>
            </div>
            <div class="member-right">
              <!-- <el-col :span="24">
                <el-form-item
                  label="User Name:"
                  prop="userName"
                >
                  <el-input
                    v-model="user.userName"
                    placeholder="Please enter"
                    maxlength="12"
                    minlength="1"
                  />
                </el-form-item>
              </el-col> -->
              <!-- 密码 -->
              <!-- <el-col :span="24">
                <el-form-item
                  label="Password:"
                  prop="password"
                >
                  <el-input
                    type="password"
                    v-model="user.password"
                    placeholder="Please enter"
                    maxlength="50"
                    show-password
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Confirm Password:"
                  prop="confirmPassword"
                >
                  <el-input
                    type="password"
                    v-model="user.confirmPassword"
                    placeholder="Please enter"
                    maxlength="50"
                    show-password
                  />
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item
                  label="Wallet Address:"
                >
                  <el-input
                    v-model="user.walletAddress"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="NFT Token ID:"
                >
                  <el-input
                    type="Number"
                    v-model="user.nftTokenId"
                    placeholder="Please enter"
                    maxlength="10"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Courtesy Title:"
                  prop="userDetails.title"
                  ref="courtesyTitleRef"
                >
                <el-select
                    v-model="user.userDetails.title"
                    placeholder="Please select your preferred title"
                    clearable
                    :style="{ width: '100%' }"
                    @change="selectCouTitle"
                  >
                    <el-option label="Mr." value="Mr."></el-option>
                    <el-option label="Mrs" value="Mrs"></el-option>
                    <el-option label="Miss" value="Miss"></el-option>
                    <el-option label="Ms" value="Ms"></el-option>
                    <el-option label="Dr." value="Dr."></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Name in Full (English):"
                  prop="userDetails.fullNameEn"
                >
                  <el-input
                    v-model="user.userDetails.fullNameEn"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="1"
                    onkeyup="value=value.replace(/[^\d\sa-zA-Z.-]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Name in Full (Chinese):"
                >
                  <el-input
                    v-model="user.userDetails.fullNameCn"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="1"
                  />
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item
                  label="Preferred Name:"
                >
                  <el-input
                    v-model="user.nickName"
                    placeholder="Please enter"
                    maxlength="12"
                    minlength="1"
                  />
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item label="Gender:" prop="sex" ref="genderRef">
                  <el-select
                    v-model="user.sex"
                    placeholder="Please select gender"
                    clearable
                    :style="{ width: '100%' }"
                    @change="genderSelect"
                  >
                    <el-option label="Male" value="0"></el-option>
                    <el-option label="Female" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Date of Birth (dd/mm/yy):" prop="birthday">
                  <el-date-picker
                    clearable
                    :editable="false"
                    v-model="user.birthday"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="Please enter"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="HKID/Passport No.:" prop="userDetails.hkidPassportNumber">
                  <el-input
                    v-model="user.userDetails.hkidPassportNumber"
                    placeholder="Please enter"
                    maxlength="21"
                    minlength="3"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Citizenship:" prop="userDetails.citizenship">
                  <el-input
                    v-model="user.userDetails.citizenship"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Residential Address:" prop="userDetails.homeAddress">
                  <el-input
                    v-model="user.userDetails.homeAddress"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="2"
                  />
                </el-form-item>
              </el-col>
              <div>
                <el-col :span="11">
                  <el-form-item label="Area Code:" prop="phoneArea" ref="areaCodeRef">
                    <el-select
                      v-model="user.phoneArea"
                      placeholder="Area"
                      clearable
                      :style="{ width: '100%' }"
                      @change="areaCodeSelect"
                    >
                      <el-option
                        v-for="(item, index) in areaList"
                        :key="index"
                        :label="item.area"
                        :value="item.area"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="13">
                  <el-form-item label="Contact Number:" prop="userDetails.contactNumber">
                    <el-input
                      v-model="user.userDetails.contactNumber"
                      placeholder="Please enter"
                      maxlength="20"
                      minlength="4"
                      onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <el-col :span="24">
                <el-form-item label="Email Address:" prop="email">
                  <el-input
                    v-model="user.email"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Marital Status:" >
                  <el-select
                    @change="selectMritalFun"
                    v-model="user.userDetails.maritalStatus"
                    placeholder="Please select"
                    clearable
                    :style="{ width: '100%' }"
                  >
                    <el-option label="Not married" value="0" ></el-option>
                    <el-option label="Married" value="1" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="isNameOfSpouse">
                <el-form-item label="Name of Spouse:" >
                  <el-input
                    v-model="user.userDetails.nameOfSpouse"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="2"
                  />
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item label="Status">
                  <el-radio-group v-model="user.status">
                    <el-radio label="0">Normal</el-radio>
                    <el-radio label="1">Disable</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col> -->
            </div>
          </el-row>
          <!-- spouse -->
          <el-row class="spouse-box" v-show="user.accountType && user.userDetails.maritalStatus==='1'">
            <div class="spouse-left">
              <div class="tit">SPOUSE</div>
              <div class="img-upl">
                <el-upload
                  :headers="headers"
                  class="avatar-uploader"
                  accept="image/*"
                  :action="uploadImgUrl"
                  :show-file-list="false"
                  :on-success="spouseHandleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="spousePhotoBlob" :src="spousePhotoBlob" class="avatar">
                  <i v-else class="el-icon-camera avatar-uploader-icon"></i>
                </el-upload>
                <p v-if="spousePhotoBlob" style="width: 155px;text-align: center;font-size: 20px;color: gray;" class="el-icon-close" @click="spousePhotoDel"></p>
                <p style="width: 155px;text-align: center;color: #878889;">Profile picture</p>
              </div>
            </div>
            <div class="spouse-right">
              <el-col :span="24">
                <el-form-item
                  label="Name in Full (English):"
                >
                  <el-input
                    v-model="user.userExtraInfo.spouseNameInFullEnglish"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="1"
                    onkeyup="value=value.replace(/[^\d\sa-zA-Z.-]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Name in Full (Chinese):"
                >
                  <el-input
                    v-model="user.userExtraInfo.spouseNameInFullChinese"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="1"
                  />
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item
                  label="Preferred Name:"
                >
                  <el-input
                    v-model="user.userExtraInfo.preferredname"
                    placeholder="Please enter"
                    maxlength="12"
                    minlength="1"
                  />
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item label="Date of Birth (dd/mm/yy):" >
                  <el-date-picker
                    clearable
                    :editable="false"
                    v-model="user.userExtraInfo.birthday"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="Please enter"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="HKID/Passport No.:" >
                  <el-input
                    v-model="user.userExtraInfo.hkidPassportNo"
                    placeholder="Please enter"
                    maxlength="21"
                    minlength="3"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Citizenship:" >
                  <el-input
                    v-model="user.userExtraInfo.citizenship"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Residential Address:" >
                  <el-input
                    v-model="user.userExtraInfo.residentialAddress"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="2"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Contact Number:" >
                  <el-input
                    v-model="user.userExtraInfo.contactNumber"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="4"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Email Address:" >
                  <el-input
                    v-model="user.userExtraInfo.emailAddress"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
            </div>
          </el-row>
          <!-- COMPANY -->
          <el-row class="company-box">
            <div class="company-left">
              <div class="tit">COMPANY INFORMATION</div>
            </div>
            <div class="company-right">
              <el-col :span="24">
                <el-form-item
                  label="Company Name:"
                  prop="userDetails.companyName"
                >
                  <el-input
                    v-model="user.userDetails.companyName"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="1"
                    :disabled="disabledCompanyName"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Nature of Business:"
                >
                  <el-input
                    v-model="user.userDetails.natureOfBusiness"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="1"
                    :disabled="disabledNatureOfBusiness"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Occupation or Position Held:"
                  prop="userDetails.occupation"
                >
                  <el-input
                    v-model="user.userDetails.occupation"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="1"
                  />
                  <!-- :disabled="disabledOccupation" -->
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Business Address:" prop="userDetails.businessAddress">
                  <el-input
                    v-model="user.userDetails.businessAddress"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="1"
                    :disabled="disabledBusinessAddress"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Office Phone:">
                  <el-input
                    type="Number"
                    v-model="user.userDetails.officePhone"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="4"
                    :disabled="disabledOfficePhone"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Office Fax:" >
                  <el-input
                    v-model="user.userDetails.officeFax"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="4"
                    :disabled="disabledOfficeFax"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Secretary Name:" >
                  <el-input
                    v-model="user.userDetails.secretaryAssistantName"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="2"
                    :disabled="disabledSecretaryAssistantName"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Secretary Contact Number:" >
                  <el-input
                    maxlength="20"
                    minlength="2"
                    v-model="user.userDetails.secretaryAssistantContactNumber"
                    placeholder="Please enter"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                    :disabled="disabledSecretaryAssistantContactNumber"
                  />
                </el-form-item>
              </el-col>
            </div>
          </el-row>
          <!-- 公司的被提名的人信息 -->
          <el-row class="member-box" v-show="!user.accountType">
            <div class="member-left">
              <div class="tit">NOMINEE</div>
              <div class="img-upl">
                <el-upload
                  :headers="headers"
                  class="avatar-uploader"
                  accept="image/*"
                  :action="uploadImgUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="applicantPhotoBlob" :src="applicantPhotoBlob" class="avatar">
                  <i v-else class="el-icon-camera avatar-uploader-icon"></i>
                </el-upload>
                <p v-if="applicantPhotoBlob" style="width: 155px;text-align: center;font-size: 20px;color: gray;" class="el-icon-close" @click="applicantPhotoDel"></p>
                <p style="width: 155px;text-align: center;color: #878889;">Profile picture</p>
              </div>
            </div>
            <div class="member-right">
              <!-- <el-col :span="24">
                <el-form-item
                  label="User Name:"
                  prop="userName"
                >
                  <el-input
                    v-model="user.userName"
                    placeholder="Please enter"
                    maxlength="12"
                    minlength="1"
                  />
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="24">
                <el-form-item
                  label="Password:"
                  prop="password"
                >
                  <el-input
                    type="password"
                    v-model="user.password"
                    placeholder="Please enter"
                    maxlength="50"
                    show-password
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Confirm Password:"
                  prop="confirmPassword"
                >
                  <el-input
                    type="password"
                    v-model="user.confirmPassword"
                    placeholder="Please enter"
                    maxlength="50"
                    show-password
                  />
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item
                  label="Wallet Address:"
                >
                  <el-input
                    v-model="user.walletAddress"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="NFT Token ID:"
                >
                  <el-input
                    type="Number"
                    v-model="user.nftTokenId"
                    placeholder="Please enter"
                    maxlength="10"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Courtesy Title:"
                  prop="userDetails.title"
                  ref="courtesyTitleRef2"
                >
                <el-select
                    v-model="user.userDetails.title"
                    placeholder="Please select your preferred title"
                    clearable
                    :style="{ width: '100%' }"
                    @change="selectCouTitle"
                  >
                    <el-option label="Mr." value="Mr."></el-option>
                    <el-option label="Mrs" value="Mrs"></el-option>
                    <el-option label="Miss" value="Miss"></el-option>
                    <el-option label="Ms" value="Ms"></el-option>
                    <el-option label="Dr." value="Dr."></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Name in Full (English):"
                  prop="userDetails.fullNameEn"
                >
                  <el-input
                    v-model="user.userDetails.fullNameEn"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="1"
                    onkeyup="value=value.replace(/[^\d\sa-zA-Z.-]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Name in Full (Chinese):"
                >
                  <el-input
                    v-model="user.userDetails.fullNameCn"
                    placeholder="Please enter"
                    maxlength="20"
                    minlength="1"
                  />
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item
                  label="Preferred Name:"
                >
                  <el-input
                    v-model="user.nickName"
                    placeholder="Please enter"
                    maxlength="12"
                    minlength="1"
                  />
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item label="Gender:" prop="sex" ref="genderRef2">
                  <el-select
                    v-model="user.sex"
                    placeholder="Please select gender"
                    clearable
                    :style="{ width: '100%' }"
                    @change="genderSelect"
                  >
                    <el-option label="Male" value="0"></el-option>
                    <el-option label="Female" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Date of Birth (dd/mm/yy):" prop="birthday">
                  <el-date-picker
                    clearable
                    :editable="false"
                    v-model="user.birthday"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="Please enter"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="HKID/Passport No.:" prop="userDetails.hkidPassportNumber">
                  <el-input
                    v-model="user.userDetails.hkidPassportNumber"
                    placeholder="Please enter"
                    maxlength="21"
                    minlength="3"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Citizenship:" prop="userDetails.citizenship">
                  <el-input
                    v-model="user.userDetails.citizenship"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Residential Address:" prop="userDetails.homeAddress">
                  <el-input
                    v-model="user.userDetails.homeAddress"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="2"
                  />
                </el-form-item>
              </el-col>
              <div>
                <el-col :span="11">
                  <el-form-item label="Area Code:" prop="phoneArea" ref="areaCodeRef2">
                    <el-select
                      v-model="user.phoneArea"
                      placeholder="Area"
                      clearable
                      :style="{ width: '100%' }"
                      @change="areaCodeSelect"
                    >
                      <el-option
                        v-for="(item, index) in areaList"
                        :key="index"
                        :label="item.area"
                        :value="item.area"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="13">
                  <el-form-item label="Contact Number:" prop="userDetails.contactNumber">
                    <el-input
                      v-model="user.userDetails.contactNumber"
                      placeholder="Please enter"
                      maxlength="20"
                      minlength="4"
                      onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <el-col :span="24">
                <el-form-item label="Email Address:" prop="email">
                  <el-input
                    v-model="user.email"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Marital Status:" >
                  <el-select
                    @change="selectMritalFun"
                    v-model="user.userDetails.maritalStatus"
                    placeholder="Please select"
                    clearable
                    :style="{ width: '100%' }"
                  >
                    <el-option label="Not married" value="0" ></el-option>
                    <el-option label="Married" value="1" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="isNameOfSpouse">
                <el-form-item label="Name of Spouse:">
                  <el-input
                    v-model="user.userDetails.nameOfSpouse"
                    placeholder="Please enter"
                    maxlength="50"
                    minlength="2"
                  />
                </el-form-item>
              </el-col>
            </div>
          </el-row>
          <!-- INTEREST -->
          <el-row class="interest-box">
            <div class="interest-left">
              <div class="tit">INTEREST</div>
            </div>
            <div class="interest-right">
              <el-col :span="24">
                <div>Share your interest with us for the latest WHALE CLUB news & promotions</div>
              </el-col>
              <div class="options-con" v-if="cities">
                <el-checkbox-group
                  v-model="user.userInterests">
                  <el-checkbox v-for="item in cities" :label="item.interestEnglish==null?item.interestChinese:item.interestEnglish" :key="item.id">{{item.interestEnglish==null?item.interestChinese:item.interestEnglish}}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div style="display:flex;align-items: center;margin-top: 20px;">
                <span style="color: #606266;font-size: 14px;">Other:</span>
                <el-input
                    v-model="user.otherInterest"
                    placeholder="Please enter"
                    maxlength="50"
                  />
              </div>
            </div>
          </el-row>
          <div class="agree-member-con" v-if="1 != 1">
            <div style="font-weight: 600;">
              I would like to become an Individual Member of WHALE CLUB and agree that –
            </div>
            <ul>
              <li>An Entry Fee currently at HKD$50,000 shall be made payable to WHALE CLUB LIMITED</li>
              <li>A MonthIy Fee currently at HKD$1,500 will be charged at the first day of each month through an autopay system</li>
              <li>The Membership is non-transferrable but resellable only through the Board</li>
              <li>The Entry Fee and Annual Fee, once paid, are not refundable in any circumstances</li>
              <li>The location of WHALE CLUB will be at 1F @ 29 LYNDHURST TERRACE, CENTRAL, HONG KONG</li>
            </ul>
          </div>
          <div class="mem-wh-cl-box">
            <div class="tit">MEMBERSHIP AT WHALE CLUB</div>
            <div>
              <div style="font-weight: 600;">INDIVIDUAL MEMBERSHIP</div>
                This Membership is open to individuals who have been invited and referred by an existing member of WHALE CLUB
              <div>
                One-time Entry Fee in the amount of HKD$50,000 to be paid at the time of application
              </div>
              <div>
                A Monthly Fee in the amount of HKD$1,500 to be paid at the beginning of each month through an autopay system
              </div>
              <div>
                A monthly credit rebate of 500 WHALE POINTS which is equivalent to HKD$500 will be added to member’s WHALE CLUB APP Wallet or in a form of e-voucher for any consumptions within the WHALE CLUB
              </div>
              <div>
                *The monthly credit rebate or coupon is valid for 1 month and can be used once only
              </div>
              <div style="font-weight: 600;margin-top: 20px;">
                PRE-SALES WELCOMING PROMOTION FOR INDIVIDUAL MEMBERSHIP
              </div>
              <div>
                First 100 WHALE CLUB new join members are entitled for 20,000 WHALE CLUB POINTS which is equivalent to HKD$20,000 for any consumptions within the WHALE CLUB
              </div>
              <div>
                The club points are valid for 6 months from the day of enrolment
              </div>
              <div style="font-weight: 600;margin-top: 20px;">
                CORPORATE MEMBERSHIP
              </div>
              <div>
                This Membership is open to corporations or partnerships which have been invited and referred by an existing member of WHALE CLUB
              </div>
              <div>
                One-time Entry Fee in the amount of HKD$250,000 to be paid at the time of application for three nominees
              </div>
              <div>
                Each corporate member is entitled to switch its nominee once every calendar year, subject to an Administrative Surcharge in the amount of HKD$5,000 per amendment
              </div>
              <div style="font-weight: 600;margin-top: 20px;">
                REFUND POLICIES
              </div>
              Any Entry Fee and Monthly Fee, once paid, are not refundable in any circumstances
              <div style="font-weight: 600;margin-top: 20px;">
                ADDITIONAL NOTES
              </div>
              <div>
                Each application for Membership to WHALE CLUB is subject to acceptance by the Admissions Team
              </div>
              <div>
                WHALE CLUB reserves the right to check references and verify employment
              </div>
              <div>
                The stated fees/dues are applicable currently and are subject to change with or without notice
              </div>
            </div>
          </div>
          <!-- STORAGE DISCLAIMER -->
          <div class="storage-disclaimer-box">
            <div class="tit">STORAGE DISCLAIMER</div>
            <div>
              Wine and cigar storage are provided within the premises of WHALE CLUB by the management of the Club, or herein referred to as “The Club” (which expression shall include its officers, employees and agents) entirely on a complimentary basis. While the Club endeavours to keep the items stored in appropriate and monitored environments with reasonable level of security, I, on behalf of myself, hereby acknowledge and agree that the Club, to the fullest extent permitted under law, does not accept and specifically disclaims any duty to prevent and accordingly also any liability for any property damage to all Relevant Persons and their respective properties. In no event will the Club be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of profits arising out of, or in connection with, the use of such storage services. Every effort is made to keep the storage service running smoothly, yet the Club cannot and will not accept liability or responsibility for damage caused or ultimately attributable to causes beyond the control of the Club.
            </div>
            <div class="canvaspanel" style="display: none;">
              <div class="canvasborder">
                <div style="width: 200px;">Member’s Signature:</div>
                <div style="border: 1px solid #c7c7c7;padding: 10px">
                  <vue-esign
                    ref="esignStorage"
                    :height="120"
                    :width="500"
                    :isCrop="isCrop"
                    :lineWidth="lineWidth"
                    :lineColor="lineColor"
                    :bgColor.sync="bgColor"
                  />
                </div>
              </div>
              <div class="buttongroup">
                <el-button @click="handleResetStorage" class="empty">Clear my signature</el-button>
                <el-button @click="handleGenerateStorage" class="signature" style="display: none;">Generate signature</el-button>
              </div>
            </div>
            <div style="margin-top: 10px;display: none;">
              <span>Date：</span>
              <span style="border-bottom: 1px solid #c7c7c7;padding: 0 20px;">{{user.signatureDate}}</span>
            </div>
          </div>
          <div class="declartion-box">
            <h3 class="tit">DECLARATION OF APPLICANT</h3>
            <div>
              <p>
                I, on behalf of myself, hereby acknowledge and agree that WHALE CLUB or herein referred to as “The Club” (which expression shall include its officers, employees and agents), to the fullest extent permitted under law, does not accept and specifically disclaims any duty to prevent and accordingly also any liability for personal injury and/or property damage to all Relevant Persons and their respective properties.
              </p>
              <p>
                Without prejudice to the above, while The Club does endeavour at all times to provide what The Club believes in good faith to be a reasonable level of security, The Club cannot and will not accept liability or responsibility for injuries or damage caused or ultimately attributable to causes beyond the control of The Club. The Club specifically disclaims any liability for personal injury and/or property damage to all relevant persons and their respective properties caused or ultimately attributable to causes beyond the control of The Club or for any disruption to access to The Club or its premises or facilities or any closure of same or to maintain daily enjoyment within The Club, specifically due to, including but not limited to, licensing issues or the fact that The Club has been fully occupied in terms of capacity.
              </p>
              <p>
                If I, or any other relevant persons, should require medical attention at any time while on The Club property or participating in any Club events/activities, I also agree and acknowledge that should we at any time suffer and sustain any incapacity so as to make it impossible or impractical to secure consent (informed or otherwise) to any medical assistance or treatment, any employee of The Club shall in such case be authorized to call for medical attention or help me as he/she deems fit. I also accept that in making such a call he/she does so as a concerned layperson and not as an expert and that in any event I, or any persons included under my Membership will indemnify and keep The Club indemnified against any and all costs, expenses, loss or claims that it may suffer arising from or in relation to medical assistance or treatment.
              </p>
              <p>
                I also, hereby confirm that the information contained in this form is true and accurate. As a Member, I agree to comply with and be bound by THE WHALE CLUB CLUB RULES, which shall be attached with this application form. Failure to do so may result in the termination of the membership.
              </p>
              <p>
                I also acknowledge that my likeness and those of any and all other relevant persons may be reproduced on or in The Club premises, publications, and/or marketing materials without prior notification or consent.
              </p>
              <p>
                I also agree to ensure that all other relevant persons are aware of the acknowledgement and agreement made on each of their behalves and that their acceptances of the same is a condition to their being allowed under cover of my Membership to have access to The Club’s premises and/or facilities.
              </p>
            </div>
            <div class="canvaspanel">
              <div class="canvasborder">
                <div style="width: 200px;"><span style="color: #F56C6C;">* </span>Applicant’s Signature:</div>
                <div style="border: 1px solid #c7c7c7;padding: 10px">
                  <vue-esign
                    ref="esign"
                    :height="120"
                    :width="500"
                    :isCrop="isCrop"
                    :lineWidth="lineWidth"
                    :lineColor="lineColor"
                    :bgColor.sync="bgColor"
                  />
                </div>
              </div>
              <div class="buttongroup">
                <el-button @click="handleReset" class="empty">Clear my signature</el-button>
                <el-button @click="handleGenerate" class="signature" style="display: none;">Generate signature</el-button>
              </div>
            </div>
            <div style="margin-top: 10px;">
              <span>Date：</span>
              <span style="border-bottom: 1px solid #c7c7c7;padding: 0 20px;">{{user.signatureDate}}</span>
            </div>
          </div>
          <!-- credit card -->
          <div class="credit-card-box" style="display: none;">
            <div class="tit">CREDIT CARD AUTHORIZATION FORM</div>
            <div>
              <p>
                This payment authorization is only for the cancellation fee based on the WHALE CLUB, hereinafter referred to as the “Club”, cancellation policy.
              </p>
              <p>
                Any cancellation or rescheduling of your booking must be made at least forty-eight (48) hours prior to your reservation time to avoid a cancellation fee. Please note that the same policy applies to any COVID-related cancellations.
              </p>
              <p>
                Cancellation Policy for Gallery Table booking
                A cancellation fee in the amount of $500 per person will be applied in the case of cancellation if such request is made within forty-eight (48) hours prior to your reservation time. All cancellation fees, if applicable, will be deducted directly from your member’s wallet or your credit card on record without prior notice. If there is insufficient balance in your member’s wallet or your credit card transaction is being denied, the club reserved the rights to request payment from you in another form of payment.
              </p>
              <p>
                Cancellation Policy for Private Room booking
                A cancellation fee in the amount of the minimum charge of the room will be applied in the case of cancellation if such request is made within forty-eight (48) hours prior to your reservation time. All cancellation fees, if applicable, will be deducted directly from your member’s wallet or your credit card on record without prior notice. If there is insufficient balance in your member’s wallet or your credit card transaction is being denied, the club reserved the rights to request payment from you in another form of payment.
              </p>
              <p>
                For pre-ordered special customized menus, a cancellation fee in the amount of 100% of the original total quoted price will be applied in the case of cancellation if such request is made within seven (7) days prior to your reservation time. In the case of a reduction in the final number of guests, a maximum of 20% of the originally committed number of guests is allowed. For a reduction in the final number of guests over 20%, 80% of the original quoted price will still be applied.
              </p>
              <p>
                Please sign and e nsure that all fields have been filled in correctly. By signing this form, you give us permission to debit your account for the amount accrued by any booking cancellation(s). All information will remain confidential.
              </p>
            </div>
            <div class="con">
              <p>CREDIT CARD INFORMATION</p>
              <div class="type">
                <el-radio-group v-model="user.userCreditCard.cardType" @input="inputCardTypeFun">
                  <el-radio label="VISA"></el-radio>
                  <el-radio label="MASTERCARD"></el-radio>
                  <el-radio label="AMEX"></el-radio>
                  <el-radio label="UNION PAY"></el-radio>
                  <br/>
                  <el-radio label="OTHER">
                    <span>OTHER:</span>
                    <el-input
                    v-model="user.userCreditCard.cardTypeOther"
                    placeholder="Please enter"
                    maxlength="50"
                    @focus="focusCardTypeOther"
                  />
                  </el-radio>

                </el-radio-group>
                <div style="display: flex;margin-top: 10px;align-items: center;">
                  <div style="flex: none;">Cardholder’s Name (as shown on the card):</div>
                  <el-input
                    v-model="user.userCreditCard.cardHolderName"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </div>
                <div style="display: flex;margin-top: 10px;align-items: center;">
                  <div style="flex: none;">Card Number:</div>
                  <el-input
                    type="Number"
                    v-model="user.userCreditCard.cardNumber"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </div>
                <div style="margin-top: 10px;">
                  Expiration Date (mm/yy):
                  <el-date-picker
                    clearable
                    :editable="false"
                    v-model="user.userCreditCard.expirationDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="Please enter"
                  >
                  </el-date-picker>
                </div>
                <div style="display: flex;margin-top: 10px;">
                  Card Identification Number (3-digit number at the back of the card):
                  <el-input
                    v-model="user.userCreditCard.idCardBack3"
                    placeholder="Please enter"
                    maxlength="50"
                  />
                </div>
                <div style="margin-top: 10px;">
                  <span>I,</span>
                  <el-input
                    class="card-readName"
                    v-model="user.userCreditCard.readName"
                    placeholder="Please enter"
                    maxlength="50"
                  />,
                  authorize WHALE CLUB LIMITED to charge my credit card above based on the abovementioned agreed cancellation policy.
                  I agree that no prior notification will be provided for any cancellation fee collected.
                  I declare that the information provided in this application form is correct. I understand that my information will be saved to file for future transactions on my account.
                </div>
              </div>
              <div class="canvaspanel">
                <div class="canvasborder">
                  <div style="width: 200px;">Member Signature:</div>
                  <div style="border: 1px solid #c7c7c7;padding: 10px">
                    <vue-esign
                      ref="esignCard"
                      :height="120"
                      :width="500"
                      :isCrop="isCrop"
                      :lineWidth="lineWidth"
                      :lineColor="lineColor"
                      :bgColor.sync="bgColor"
                    />
                  </div>
                </div>
                <div class="buttongroup">
                  <el-button @click="handleResetCard" class="empty">Clear my signature</el-button>
                  <el-button @click="handleGenerateCard" class="signature" style="display: none;">Generate signature</el-button>
                </div>
              </div>
              <div style="margin-top: 10px;">
                <span>Date：</span>
                <span style="border-bottom: 1px solid #c7c7c7;padding: 0 20px;">{{user.signatureDate}}</span>
              </div>
            </div>
          </div>
        </div>

      </el-form>
      <!-- 預覽 -->
      <el-dialog
        title="Registration Content Preview"
        :visible.sync="dialogVisible"
        width="85%"
        top="8vh"
        :before-close="handleClose">
        <div style="height: 55vh;overflow-y: auto;">
          <perviewForm
          :userInfo="user" :cities="cities" @eformBox="isEformBoxFun" @dialogVisibleEform="dialogVisibleEformFun"></perviewForm>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="goBackFun">go back</el-button>
          <el-button type="primary" @click="submitFun">Submit</el-button>
          &nbsp;<el-checkbox v-model="agreeAcceptChecked">I agree to receive latest news and promotional materials from Whale Club</el-checkbox>
        </span>
      </el-dialog>
      <div slot="footer" class="submit-form">
        <el-button type="primary" @click="previewFun"> preview </el-button>
      </div>
    </div>
    <div v-if="!isEformBox">
      <perviewForm :userInfo="user" :cities="cities" @eformBox="isEformBoxFun" @dialogVisibleEform="dialogVisibleEformFun"></perviewForm>
    </div>
  </div>
</template>

<script>
// const cityOptions = ['F & B Promotion', 'Wine Appreciation', 'Cigar workshop', 'Private/Corporate Events',
//   'Art & Culture','Auctions','Crypto & web 3.0','Lifestyle & Wellness','Music / Late Night Events','Travel'
// ];
import {addUser, listUserLevel,userExpireTime,treeselect,getUser,getDept,htmltopdf,getDisplayList } from "@/api/index";
import { removeToken,getToken } from '@/utils/auth'
import perviewForm from "@/components/perviewForm.vue";

export default {
  components:{
    perviewForm
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_BASE_UPLOAD, // 上传的图片服务器地址
      applicantPhotoBlob:'',
      spousePhotoBlob:'',
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      isExistCompanyName:false,
      isEformBox:true,
      dialogVisible: false,
      userLevelList:null,
      postOptions: [],// 岗位选项
      restaurants:[],
      deptOptions: undefined,// 部門树选项
      agreeAcceptChecked:false,
      user:{
        accountType:true,
        pdfCustomId:'',
        displayId:'',
        birthday:'',
        deptId:'',
        email:'',
        levelName:2,
        membershipExpireTime:'',
        membershipLevel:2,
        nftTokenId:'',
        nickName:'',
        password:'',
        confirmPassword:'',
        phoneArea:'',
        phonenumber:'',
        postIds:[],
        postTypeName:'',
        remark:'',
        sex:'',
        status:1,//1 表示禁用 0 正常使用
        userId:'',
        userName:'',
        userType:'',
        walletAddress:'',
        userInterests:[],
        otherInterest:'',
        signature:'',
        signatureDate:'',
        sendEmail:1,
        userCompanyName:'',
        applicantPhotoBase64:'',
        spousePhotoBase64:'',
        agreeAccept:0,
        userDetails:{
          businessAddress:'',
          citizenship:'',
          occupation:'',
          companyName:'',
          contactNumber:'',
          firstName:'',
          fullNameCn:'',
          fullNameEn:'',
          hkidPassportNumber:'',
          homeAddress:'',
          lastName:'',
          maritalStatus:'1',
          middleName:'',
          nameOfSpouse:'',
          natureOfBusiness:'',
          officeFax:'',
          officePhone:'',
          recommendedBy:'',
          secretaryAssistantContactNumber:'',
          secretaryAssistantName:'',
          title:'',
          weddingAnniversary:'',
        },
        userExtraInfo:{
          spouseNameInFullEnglish:'',
          spouseNameInFullChinese:'',
          hkidPassportNo:'',
          citizenship:'',
          residentialAddress:'',
          contactNumber:'',
          emailAddress:'',
          applicantPhoto:'',
          spousePhoto:''
        },
        userCreditCard:{
          cardType:'',
          cardTypeOther:'',
          cardHolderName:'',
          cardNumber:'',
          expirationDate:'',
          idCardBack3:'',
          readName:'',
          signature:'',
          signatureDate:''
        },
        userStorageDisclaimer:{
          signature:'',
          signatureDate:''
        }
      },
      disabledSelectMaster:false,
      userLevelCode:null,
      rules: {
        // 表单校验
        // userName:[
        //   { required: true, message: 'User name cannot be empty', trigger: 'blur' },
        //   { min: 2, max: 12, message: "User Name length must be between 2 and 12", trigger: "blur"}
        // ],
        password:[
          { required: true, message: 'password cannot be empty', trigger: 'blur' }
        ],
        confirmPassword:[
          { required: true, message: 'Confirm Password cannot be empty', trigger: 'blur' }
        ],
        nftTokenId:[
          { required: true, message: 'NFT token Id cannot be empty', trigger: 'blur' }
        ],
        walletAddress:[
          { required: true, message: 'Wallet address cannot be empty', trigger: 'blur' }
        ],
        sex:[
          { required: true, message: 'Gender cannot be empty', trigger: 'blur' }
        ],
        'userDetails.fullNameEn': [
          {
            required: true,
            message: "Name in Full (English) cannot be empty",
            trigger: "blur",
          },
          {
            min: 1,
            max: 50,
            message: "Name in Full (English) length must be between 1 and 50",
            trigger: "blur",
          },
        ],
        'birthday': [
          { required: true, message: 'Birthday cannot be empty', trigger: 'blur' }
        ],
        'userDetails.hkidPassportNumber':[
          { required: true, message: 'HKID/Passport No. cannot be empty', trigger: 'blur' },
          { min: 3, max: 21, message: "HKID/Passport No. length must be between 3 and 21", trigger: "blur"}
        ],
        'userDetails.citizenship':[
          { required: true, message: 'Citizenship cannot be empty', trigger: 'blur' }
        ],
        'userDetails.homeAddress':[
          { required: true, message: 'Residential Address cannot be empty', trigger: 'blur' },
          { min: 2, max: 50, message: "Residential Address length must be between 2 and 50", trigger: "blur"}
        ],
        'userDetails.contactNumber':[
          { required: true, message: 'Contact Number cannot be empty', trigger: 'blur' },
          { min: 4, max: 20, message: "Contact Number length must be between 4 and 20", trigger: "blur"}
        ],
        phoneArea:[
          { required: true, message: 'Phone area cannot be empty', trigger: 'blur' }
        ],
        'email':[
          { required: true, message: 'Email Address cannot be empty', trigger: 'blur' },
          { type: "email", message: "Please enter the correct email address", trigger: ["blur", "change"]}
        ],
        'userDetails.companyName':[
          { required: true, message: 'Company Name cannot be empty', trigger: 'blur' },
          { min: 1, max: 20, message: "Company Name length must be between 1 and 20", trigger: "blur"}
        ],
        postIds:[
          { required: true, message: 'Please select an account type', trigger: 'blur' }
        ],
        'userDetails.occupation':[
          { required: true, message: 'Occupation or Position Held cannot be empty', trigger: 'blur' },
          { min: 1, max: 20, message: "Occupation or Position Held length must be between 1 and 20", trigger: "blur"}
        ],
        'userDetails.businessAddress':[
          { required: true, message: 'Business Address cannot be empty', trigger: 'blur' },
          { min: 1, max: 50, message: "Business Address length must be between 1 and 50", trigger: "blur"}
        ],
        'userDetails.officePhone':[
          { required: true, message: 'Office Phone cannot be empty', trigger: 'blur' }
        ],
        'membershipLevel':[
          { required: true, message: 'Account Type cannot be empty', trigger: 'blur' }
        ],
        'userDetails.title':[
          { required: true, message: 'Courtesy title cannot be empty', trigger: 'blur' }
        ]
      },
      signatureTitle: "手写签名",
      lineWidth: 10,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      cities: [],
      areaList: [
        { areaId: 1, area: 852 },
        { areaId: 2, area: 853 },
        { areaId: 3, area: 86 },
        { areaId: 4, area: 886 },
      ],
      htmlCon:'',
      disabledCompanyName:false,
      disabledNatureOfBusiness:false,
      disabledOccupation:false,
      disabledBusinessAddress:false,
      disabledOfficePhone:false,
      disabledOfficeFax:false,
      disabledSecretaryAssistantName:false,
      disabledSecretaryAssistantContactNumber:false,
      deptInfo:{},
      isExistDeptName:false,
      isNameOfSpouse:true,
      maximumLengthDis:6
    }
  },
  mounted(){
  },
  created() {
    //年
    let year = new Date().getFullYear();
    //月份是从0月开始获取的，所以要+1;
    let month = new Date().getMonth() +1;
    //日
    let day = new Date().getDate();
    this.user.signatureDate=year+'-'+month+'-'+day

    listUserLevel({ pageNum: 1, pageSize: 100, status: 0}).then((response) => {
      this.userLevelList = response.rows;
    });
    this.getTreeselect()
    getUser().then((response) => {
      // console.log(response.posts)
      this.postOptions = response.posts;//賬號類型 master sub Account
      this.roleOptions = response.roles;
    });
    getDisplayList().then((res)=>{
      this.cities=res.data
    })
  },
  methods: {
    isEformBoxFun(e){
      this.isEformBox=e
    },
    dialogVisibleEformFun(e){
      this.dialogVisible=e
    },
    //婚姻狀態 為未婚 清空填寫的內容，避免提交
    selectMritalFun(){
      if(this.user.userDetails.maritalStatus === '0'){
        this.user.userExtraInfo.spouseNameInFullEnglish=''
        this.user.userExtraInfo.spouseNameInFullChinese=''
        this.user.userExtraInfo.preferredname=''
        this.user.userExtraInfo.birthday=''
        this.user.userExtraInfo.hkidPassportNo=''
        this.user.userExtraInfo.citizenship=''
        this.user.userExtraInfo.residentialAddress=''
        this.user.userExtraInfo.contactNumber=''
        this.user.userExtraInfo.emailAddress=''
        this.user.userExtraInfo.sex=''
        this.user.userDetails.nameOfSpouse=''
        this.isNameOfSpouse=false
      }else{
        this.isNameOfSpouse=true
      }
    },
    //搜索公司
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;//已註冊的全部的公司名稱
      var results = queryString ? JSON.parse(JSON.stringify(restaurants)).filter(this.createStateFilter(queryString)) : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb( JSON.parse(JSON.stringify(results)) );
      }, 1000 * Math.random());

      // console.log(results)
      if(results.length==0){
        this.isExistCompanyName=false //是否存在已註冊過了的 公司名稱
        this.resetCompanyName() //重置 input啟用狀態

      }else{
        let resPeptArr=[]
        results.forEach(item=>{
          resPeptArr.push(item.value)
        })
        if(resPeptArr.indexOf(this.user.userDetails.companyName) >=0){ // 判斷是否已存在的 公司
          this.isExistDeptName=true
        }else{
          this.isExistDeptName=false
        }
        if(this.isExistDeptName){
          //自動填充公司內容
          // this.user.userDetails.companyName=this.deptInfo.deptName
          this.user.userDetails.natureOfBusiness=this.deptInfo.natureOfBusiness
          this.user.userDetails.occupation=this.deptInfo.occupation
          this.user.userDetails.businessAddress=this.deptInfo.businessAddress
          this.user.userDetails.officePhone=this.deptInfo.officePhone
          this.user.userDetails.officeFax=this.deptInfo.officeFax
          this.user.userDetails.secretaryAssistantName=this.deptInfo.secretaryName
          this.user.userDetails.secretaryAssistantContactNumber=this.deptInfo.secretaryContactNumber

          // 公司信息的 input 狀態為禁用
          if(this.user.userDetails.companyName){
            this.disabledCompanyName=true
            this.disabledNatureOfBusiness=true
            this.disabledOccupation=true
            this.disabledBusinessAddress=true
            this.disabledOfficePhone=true
            this.disabledOfficeFax=true
            this.disabledSecretaryAssistantName=true
            this.disabledSecretaryAssistantContactNumber=true
          }
          this.isExistCompanyName=true //是否存在已註冊過了的 公司名稱

        }else{
          this.resetCompanyName() //重置 input啟用狀態
          this.isExistCompanyName=false //是否存在已註冊過了的 公司名稱

        }
      }
    },
    //选择 Courtesy Title
    selectCouTitle(){
      this.$refs.courtesyTitleRef.clearValidate()//手动移除校验结果
      this.$refs.courtesyTitleRef2.clearValidate()//手动移除校验结果
    },
    //选择 性别
    genderSelect(){
      this.$refs.genderRef.clearValidate()//手动移除校验结果
      this.$refs.genderRef2.clearValidate()//手动移除校验结果
    },
    //选择区号
    areaCodeSelect(){
      this.$refs.areaCodeRef.clearValidate()//手动移除校验结果
      this.$refs.areaCodeRef2.clearValidate()//手动移除校验结果
    },
    //公司信息的 清空 啟用狀態 非禁用
    resetCompanyName(){
      //自動填充公司內容
      // this.user.userDetails.companyName=''
      this.user.userDetails.natureOfBusiness=''
      this.user.userDetails.occupation=''
      this.user.userDetails.businessAddress=''
      this.user.userDetails.officePhone=''
      this.user.userDetails.officeFax=''
      this.user.userDetails.secretaryAssistantName=''
      this.user.userDetails.secretaryAssistantContactNumber=''

      // 公司信息的 input 狀態為禁用
      this.disabledCompanyName=false
      this.disabledNatureOfBusiness=false
      this.disabledOccupation=false
      this.disabledBusinessAddress=false
      this.disabledOfficePhone=false
      this.disabledOfficeFax=false
      this.disabledSecretaryAssistantName=false
      this.disabledSecretaryAssistantContactNumber=false
    },
    createStateFilter(queryString) {
      return (stateDeptName) => {
        return (stateDeptName.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //公司賬號 選擇公司名
    handleSelect(item) {
      this.$refs.elAutocomplete.clearValidate()//手动移除校验结果
      this.isExistCompanyName=false //是否存在已註冊過了的 公司名稱
      this.user.postIds=[]

      // this.user.userCompanyName=item.value;
      this.user.userDetails.companyName=item.value;

      if(item.id) {
        getDept(item.id).then((res)=>{
          // console.log(res)
          this.deptInfo=res.data
          if(res.data.leader) {
            this.disabledSelectMaster = true;
            this.isExistCompanyName=true //是否存在已註冊過了的 公司名稱
          } else {
            this.disabledSelectMaster = false;
          }

          //自動填充公司內容
          this.user.userDetails.companyName=res.data.deptName
          this.user.userDetails.natureOfBusiness=res.data.natureOfBusiness
          this.user.userDetails.occupation=res.data.occupation
          this.user.userDetails.businessAddress=res.data.businessAddress
          this.user.userDetails.officePhone=res.data.officePhone
          this.user.userDetails.officeFax=res.data.officeFax
          this.user.userDetails.secretaryAssistantName=res.data.secretaryName
          this.user.userDetails.secretaryAssistantContactNumber=res.data.secretaryContactNumber

          // 公司信息的 input 狀態為禁用
          this.disabledCompanyName=true
          this.disabledNatureOfBusiness=true
          this.disabledOccupation=true
          this.disabledBusinessAddress=true
          this.disabledOfficePhone=true
          this.disabledOfficeFax=true
          this.disabledSecretaryAssistantName=true
          this.disabledSecretaryAssistantContactNumber=true

        })
      }
    },
    //公司名称input
    companyNameIntFun(obj){
      var restaurants = this.restaurants;//已註冊的全部的公司名稱
      this.user.postIds=[]
      restaurants.forEach((item)=>{
        if(item.value == obj){
          this.handleSelect(item)
        }
      })

    },
    //公司名称失去焦点时候
    companyNameIntBlur(obj){
      var restaurants = this.restaurants;//已註冊的全部的公司名稱
      this.user.postIds=[]
      restaurants.forEach((item)=>{
        if(item.value == obj){
          this.handleSelect(item)
        }
      })
    },
    postIdsTypeSelect(obj){
      this.user.postIds=[]
      this.user.postIds[0]=obj
      // 账号类型是数字id 换成对应的账号类型名称字符串 （但最终要提交的是对应的数字 id）
      this.postOptions.forEach(item =>{
        if(this.user.postIds[0] == item.postId){
          this.user.postIds[0]=item.postName
          this.user.postTypeName=item.postName
        }
      })

    },
    //查询部門下拉树结构 已經創建的公司名稱
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = response.data;
        this.restaurants =response.data[0].children;
        for(let i=0;i<response.data[0].children.length;i++) {
          this.restaurants[i].value = response.data[0].children[i].label
        }
      });
    },
    //簽名 清空
    handleReset() {
      this.$refs.esign.reset();
      this.user.signature = '';
    },
    //簽名 確認
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.user.signature = res;

          // let str=this.base64ImgtoFile(res)
          // console.log(this.user.signature);
          // let formData = new FormData();
          // formData.append("file", str);
          // console.log(formData)
        })
        .catch((err) => {
          console.log("请签名1",err);// 画布没有签字时会执行这里 'Not Signned'
        });
    },
    handleResetStorage(){
      this.$refs.esignStorage.reset();
      this.user.userStorageDisclaimer.signature = '';
    },
    handleGenerateStorage(){
      this.$refs.esignStorage
        .generate()
        .then((res) => {
          // console.log(res);
          this.user.userStorageDisclaimer.signature=res //签名
          this.user.userStorageDisclaimer.signatureDate=this.user.signatureDate //日期
        })
        .catch((err) => {
          //console.log("请签名2",err);// 画布没有签字时会执行这里 'Not Signned'
        });
    },
    handleResetCard(){
      this.$refs.esignCard.reset();
      this.user.userCreditCard.signature = '';
    },
    handleGenerateCard(){
      this.$refs.esignCard
        .generate()
        .then((res) => {
          // console.log(res);
          this.user.userCreditCard.signature=res //签名
          this.user.userCreditCard.signatureDate=this.user.signatureDate //日期
        })
        .catch((err) => {
          //console.log("请签名3",err);// 画布没有签字时会执行这里 'Not Signned'
        });
    },

    //base64 轉 文件流
    base64ImgtoFile(dataurl, fileName = "file") {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split("/")[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${fileName}.${suffix}`, { type: mime });
    },
    // 账户类型
    setDeadline(record) {
      this.isNameOfSpouse=true
      this.resetUserInfo()
      this.applicantPhotoBlob=''
      this.spousePhotoBlob=''
      this.user.userExtraInfo.applicantPhoto=''
      this.user.userExtraInfo.spousePhoto=''
      this.user.spousePhotoBase64=''
      this.user.applicantPhotoBase64=''
      userExpireTime(record).then((response) => {
        this.user.membershipExpireTime = response.msg;
      });
      this.user.postIds=[]
      if(record === 2){
        this.user.accountType=true
        // this.maximumLengthDis=8
      }else{
        this.user.accountType=false
        // this.maximumLengthDis=5
      }
      this.resetCompanyName()
    },
    isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    //Radio 选中 信用卡
    inputCardTypeFun(e){
      if(e !=='OTHER'){
        this.user.userCreditCard.cardTypeOther=''
      }
    },
    //选择其他选项后，状态也随之改变
    focusCardTypeOther(){
      this.user.userCreditCard.cardType='OTHER'
    },
    //預覽按钮
    previewFun() {
      this.user.pdfCustomId=parseInt(new Date().getTime() / 1000) + '';
      // var displayIdRegExp
      // var isNumberDisplayId
      // var displayIdLast
      // var displayIdLastVer
      // Membership Number验证 规则
      /*if(this.user.accountType){//个人账户
        // var regExpI = new RegExp("^[a-zA-Z]")
        // displayIdRegExp=!regExpI.exec(this.user.displayId) // 判断首个字符 是否为字母
        displayIdRegExp=false
        isNumberDisplayId=(!isNaN(parseFloat(this.user.displayId.substring(this.user.displayId.length-3,this.user.displayId.length)))) && (isFinite(this.user.displayId.substring(this.user.displayId.length-3,this.user.displayId.length)))
      }else{//公司账户
        var regExpC = new RegExp("^[c-cC-C]")
        displayIdRegExp=!regExpC.exec(this.user.displayId)
        isNumberDisplayId=(!isNaN(parseFloat(this.user.displayId.substring(1,this.user.displayId.length-1)))) && (isFinite(this.user.displayId.substring(1,this.user.displayId.length-1)))
        displayIdLast=this.user.displayId.charAt(this.user.displayId.length - 1 )
        var abc =/[a-cA-C]/;
        displayIdLastVer=abc.test(displayIdLast)
      }*/

      /*var randomPassword=''//随机密码
      for(var i=0;i<6;i++){
        randomPassword+=Math.floor(Math.random()*10)
      }
      this.user.password=randomPassword*/

      Promise.all([
        this.handleGenerate(),
        // this.handleGenerateStorage(),
        // this.handleGenerateCard(),
        // console.log(this.user)
      ]).then(() =>{
        if(!this.user.displayId){
          this.$alert('Membership Number cannot be empty','Tips', {confirmButtonText: 'confirm',type: 'warning'})
        }
        /*if(this.user.accountType){//个人账户
          if((displayIdRegExp) || (!isNumberDisplayId)){
            this.$alert('Incorrect format of membership number.'+`<br/>`+'Format example: [name initial] + [3 digits]','Tips', {confirmButtonText: 'confirm',type: 'warning',dangerouslyUseHTMLString: true})
            return false
          }
        }else{//公司账号
            if(this.disabledSelectMaster==true && this.isExistCompanyName){
              console.log('sub')
              var abc =/[a-aA-A]/;
              displayIdLastVer=!abc.test(displayIdLast)
              console.log(displayIdLastVer)
            }else{
              var abc2 =/[b-zB-Z]/;
              displayIdLastVer=!abc2.test(displayIdLast)
              console.log('master')
              console.log(displayIdLastVer)
            }
          if((displayIdRegExp) || (!isNumberDisplayId) || (!displayIdLastVer)){
            this.$alert('Incorrect format of membership number.'+`<br/>`+'Format example: C + [3 digits]','Tips', {confirmButtonText: 'confirm',type: 'warning',dangerouslyUseHTMLString: true})
            return false
          }
        }*/
        if(!this.user.userDetails.recommendedBy){
          this.$alert('Member Sponsor cannot be empty','Tips', {confirmButtonText: 'confirm',type: 'warning'})
        }else if(this.user.userDetails.recommendedBy.length < 1 || this.user.userDetails.recommendedBy.length > 30){
          this.$alert('Member Sponsor length must be between 1 and 30','Tips', {confirmButtonText: 'confirm',type: 'warning'})
        }/*else if(this.user.password !== this.user.confirmPassword){
          this.$alert('The two passwords are inconsistent','Tips', {confirmButtonText: 'confirm',type: 'warning'})
        }*/ else if(!this.user.signature) {
          this.$alert('Please complete the electronic signature','Tips', {confirmButtonText: 'confirm',type: 'warning'})
        } else {
          if(this.isNumber(this.user.postIds) && (typeof this.user.postIds === 'number')) {
            let tmpPost = this.user.postIds;
            this.user.postIds=[]
            this.user.postIds.push(tmpPost)
          }
          this.user.phonenumber=this.user.userDetails.contactNumber
          this.$refs["user"].validate((valid) => {
            if (valid) {
              this.dialogVisible=true
              // 账号类型是字符串 换成对应的账号类型数字id （但最终要提交的是对应的数字 id）
              this.postOptions.forEach(item =>{
                if(this.user.postIds[0] == item.postName){
                  this.user.postIds[0]=item.postId
                  // console.log(this.user.postIds)
                }
              })
              if(this.user.otherInterest){
                this.user.userInterests.push(this.user.otherInterest)
              }
            }
          })
        }
      })
    },
    // 退出登录
    LogOutFun(){
      removeToken()
      this.$router.push({path:'/login'})
    },
    handleClose(done) {
      // this.$confirm('确认关闭？').then(()=> {done();}).catch(()=> {});
      this.dialogVisible=false
      this.user.userInterests.forEach((item,i) =>{
        if(item == this.user.otherInterest){
          this.user.userInterests.splice(i,i+1)
        }
      })
    },
    //取消
    goBackFun(){
      this.dialogVisible = false
      this.user.userInterests.forEach((item,i) =>{
        if(item == this.user.otherInterest){
          this.user.userInterests.splice(i,i+1)
        }
      })
    },
    //提交 註冊內容
    submitFun(){
      Promise.all([
        this.$confirm('Confirm to submit?', 'Tips', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          //确认提交
          this.dialogVisible = false
          this.isEformBox = false
          // this.emailChecked?this.user.sendEmail=1:this.user.sendEmail=0 //是否自动发送邮件
          this.agreeAcceptChecked?this.user.agreeAccept=1:this.user.agreeAccept=0

          }).catch(() => {
            //取消操作
            this.$message({ type: 'info', message: 'Canceled' });
        })
      ]).then(()=>{
        if(!this.isEformBox){
          setTimeout(()=>{
            this.htmlCon = document.documentElement.outerHTML;
            // console.log(this.htmlCon)
            addUser(this.user).then((res) => {
              if (res.code === 200) {
                this.isNameOfSpouse=true
                this.user.postIds=[]
                this.applicantPhotoBlob=''
                this.spousePhotoBlob=''
                this.user.userExtraInfo.applicantPhoto=''
                this.user.userExtraInfo.spousePhoto=''
                this.user.spousePhotoBase64=''
                this.user.applicantPhotoBase64=''
                this.$message({ message: res.msg, type: "success",duration:1500 });
                this.htmltopdfFun()
                this.getTreeselect()//提交成功后需要 再次请求获取最新的公司名称，以防公司账户 input选择公司时候不是最新的数据

                // this.resetUserInfo() //註冊成功後 清空內容
              } else {
                // this.user.postIds=2
                this.$message.error("System error");
              }
            });
            this.isEformBox = true

            // 账号类型是数字id 换成对应的账号类型名称字符串 （但最终要提交的是对应的数字 id）
            this.postOptions.forEach(item =>{
              if(this.user.postIds[0] == item.postId){
                this.user.postIds[0]=item.postName
                this.user.postTypeName=item.postName
              }
            })

            this.user.userInterests.forEach((item,i) =>{//清除其他兴趣的内容，防止累积
              if(item == this.user.otherInterest){
                this.user.userInterests.splice(i,i+1)
              }
            })
          },500)
        }
      })
    },
    //生成pdf
    htmltopdfFun(){
      const loading = this.$loading({
        lock: true,
        text: 'The pdf is being generated...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.4)'
      });

      setTimeout(()=>{
        htmltopdf({ pdfCustomId: this.user.pdfCustomId, htmlContent: this.htmlCon,fullName:this.user.userDetails.fullNameEn }).then(
          (blob) => {
            let binaryData = [];
            binaryData.push(blob);
            let link = window.URL.createObjectURL(
              new Blob(binaryData, { type: "application/pdf; charset=utf-8" })
            );
            window.open(link, "", "width = 800,height = 1000");
            loading.close()
          }).catch(err => {
            console.log(err)
            loading.close()
            this.$alert('Pdf generation failed ===》 '+err,'Tips', {confirmButtonText: 'ok',type: 'warning'}).then(() => {
              this.$router.push({ path: "/eForm" });
            })
          })
          this.resetUserInfo() //註冊成功後 清空內容
      },200)
    },
    // 清空內容userInfo 重置
    resetUserInfo(){
      this.user.displayId=''
      this.user.userDetails.recommendedBy=''

      this.user.userName=''
      this.user.password=''
      this.user.confirmPassword=''
      this.user.walletAddress=''
      this.user.nftTokenId=''
      this.user.userDetails.fullNameEn=''
      this.user.userDetails.fullNameCn=''
      this.user.nickName=''
      this.user.sex=''
      this.user.birthday=''
      this.user.userDetails.hkidPassportNumber=''
      this.user.userDetails.citizenship=''
      this.user.userDetails.homeAddress=''
      this.user.phoneArea=''
      this.user.userDetails.contactNumber=''
      this.user.email=''
      this.user.userDetails.maritalStatus='1'
      this.user.userDetails.nameOfSpouse=''
      this.user.userDetails.title=''

      this.user.userExtraInfo.spouseNameInFullEnglish=''
      this.user.userExtraInfo.spouseNameInFullChinese=''
      this.user.userExtraInfo.preferredname=''
      this.user.userExtraInfo.birthday=''
      this.user.userExtraInfo.hkidPassportNo=''
      this.user.userExtraInfo.citizenship=''
      this.user.userExtraInfo.residentialAddress=''
      this.user.userExtraInfo.contactNumber=''
      this.user.userExtraInfo.emailAddress=''
      this.user.userExtraInfo.sex=''


      this.user.userDetails.companyName=''
      this.user.userDetails.natureOfBusiness=''
      this.user.userDetails.occupation=''
      this.user.userDetails.businessAddress=''
      this.user.userDetails.officePhone=''
      this.user.userDetails.officeFax=''
      this.user.userDetails.secretaryAssistantName=''
      this.user.userDetails.secretaryAssistantContactNumber=''

      this.user.userInterests=[]
      this.user.otherInterest=''

      this.agreeAcceptChecked=false

      this.handleReset()
    },
    // 头像 上传前
    beforeAvatarUpload(file) {
      // this.yasuoFun(file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' //图片格式是否为png或jpg
      const isLt = file.size / 1024 / 1024
      if (!isJpgOrPng) {
        this.$message.error('文件格式错误！')
      } else if(isLt>1){//判断图片大小是否超过2MB
        const that = this
        return new Promise(resolve => {
          const reader = new FileReader()
          const image = new Image()
          image.onload = (imageEvent) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const originWidth = image.width
            const originHeight = image.height
            // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
            let maxWidth = 800, maxHeight = 800
            // 目标尺寸
            let targetWidth = originWidth, targetHeight = originHeight
            // 图片尺寸超过800x800的限制
            if (originWidth > maxWidth || originHeight > maxHeight) {
              if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
              } else {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
              }
            }
            canvas.width = targetWidth
            canvas.height = targetHeight
            context.clearRect(0, 0, targetWidth, targetHeight)
            context.drawImage(image, 0, 0, targetWidth, targetHeight)
            const dataUrl = canvas.toDataURL('image/jpeg', 0.8)
            const blobData = that.dataURLtoBlob(dataUrl, 'image/jpeg')
            resolve(blobData)
          }
          reader.onload = (e => { image.src = e.target.result; })
          reader.readAsDataURL(file)
        })
      }
      return isJpgOrPng && isLt
    },
    // 头像上传 成功时
    handleAvatarSuccess(res, file){
      this.applicantPhotoBlob = URL.createObjectURL(file.raw); //blob类型
      this.user.userExtraInfo.applicantPhoto = res.fileName;
      this.blobToBase64(file.raw).then(res => {
        // 转化后的base64
        this.user.applicantPhotoBase64=res
      })
    },
    //配偶 头像上传 成功时
    spouseHandleAvatarSuccess(res, file){
      this.spousePhotoBlob = URL.createObjectURL(file.raw); //blob类型
      this.user.userExtraInfo.spousePhoto = res.fileName;
      this.blobToBase64(file.raw).then(res => {
        // 转化后的base64
        this.user.spousePhotoBase64=res
      })
      // console.log(this.user)
    },
    applicantPhotoDel(){
      this.applicantPhotoBlob=''
      this.user.userExtraInfo.applicantPhoto=''
      this.user.applicantPhotoBase64=''
    },
    spousePhotoDel(){
      this.spousePhotoBlob=''
      this.user.userExtraInfo.spousePhoto=''
      this.user.spousePhotoBase64=''
    },
    //图片转二进制
    dataURLtoBlob(dataURL, type) {
      var binary = atob(dataURL.split(',')[1])
      var array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      return new Blob([new Uint8Array(array)], { type: type })
    },
    // blob格式 转base64
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error('文件流异常'));
        };
      });
    }
  },
};
</script>
<style scoped>
.e-form-box /deep/ .el-input__inner{
  /* background-color: #000000; */
  border-radius: inherit;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  /* color: #fff; */
}
.e-form-box /deep/ .el-input__inner:focus{
  border-color: #c7c7c7;
}
.e-form-box /deep/ .el-select .el-input.is-focus .el-input__inner{
  border-color: #c7c7c7;
}
.e-form-box /deep/ .el-textarea__inner{
  background-color: #000;
  border: 1px solid #c7c7c7;
  /* color: #fff; */
}
.e-form-box /deep/ .el-dialog__header{
  border-bottom: 1px solid #e8ecef;
}
.el-button--info.is-plain{
  color: #c7c7c7;
  background: #000000;
  border-color: #c7c7c7;
  border: 1px solid;
}
</style>
<style lang="scss" scoped>
.e-form-box {
  font-size: 14px;
  padding: 2% 3%;
  .top-con{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .log-out{
      font-weight: bold;
      color: #000000;
      font-size: 16px;
      .el-icon-switch-button{
        font-weight: bold;
      }
    }
  }
  .sponsor-top-box,.member-box,.spouse-box,.company-box,.interest-box{
    padding: 10px;
    border-top: 2px solid #000;
  }
  .acco-com-lst{
    display: flex;
    justify-content: flex-end;
  }
  .member-box,.spouse-box,.company-box,.interest-box{
    border-top: none;
  }
  .img-upl{
    .avatar-uploader  {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 155px;
      height: 155px;
      margin-top: 50px;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 155px;
      height: 155px;
      line-height: 155px;
      text-align: center;
    }
    .avatar {
      width: 155px;
      height: 155px;
      display: block;
    }
  }

  .el-row{
    display: flex;
  }
  .member-left,.spouse-left,.company-left,.interest-left{
    border-right: 1px solid #bfbfbf;
    .tit{
      width: 200px;
    }
  }
  .interest-right{
    padding: 10px;
    .options-con{
      // margin-top: 40px;
      .el-checkbox{
        margin-top: 35px;
      }
    }
  }
  .agree-member-con{
    padding: 10px;
  }
  .declartion-box{
    .tit{
      text-align: center;
      font-weight: 600;
      margin: 20px 10px 10px;
      font-size: 16px;
    }

  }
  .mem-wh-cl-box , .credit-card-box,.storage-disclaimer-box{
    .tit{
      text-align: center;
      font-weight: 600;
      margin: 20px 10px 10px;
      font-size: 16px;
    }
  }
}
.canvaspanel {
  background: #fff;
  padding: 10px 0;
  margin-top: 20px;
  border-bottom: 1px solid #c7c7c7;
  .canvasborder{
    display: flex;
    padding-top: 10px;
  }
}
.buttongroup {
  margin-top: 10px;
  .el-button {
    // background-color: #000000;
    // color: #c7c7c7;
    // border: 1px solid #c7c7c7;
  }
}
.submit-form {
  text-align: center;
  margin-top: 50px;
  .el-button {
    width: 42%;
    height: 46px;
    // background-color: #000000;
    // color: #c7c7c7;
    // border: 1px solid #c7c7c7;
    font-size: 18px;
  }
}
.card-readName{
  width: 20%;
}
.corporate-company-box{
  border-top: 2px solid #000;
  padding: 10px;
  margin-top: 15px;
}
.corporate-nominee-box{
  border-top: 2px solid #000;
  padding: 10px;
}
</style>
