import Vue from 'vue'
import App from './App.vue'
//引入路由
import router from '@/router/index'

//element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

//畫布 電子簽名
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

// import Vconsole from 'vconsole'
// let vconsole =new Vconsole();
// Vue.use(vconsole)

Vue.config.productionTip = false

import Cookies from 'js-cookie'
//路由守衛beforEach判斷是否需要登錄
router.beforeEach((to,from,next)=>{
  if(to.matched.some(record=>record.meta.requireauth)){//判斷該路由是否需要登錄權限 [requireauth是配置路由時候設置的meta屬性]
    if(Cookies.get('eform-token')){//是否有token存在
      next()
    }else{
      next({
        path:'/login',
        query:{redirect: to.fullPath}//將跳轉的路由path作為參數，登錄成功後跳轉到路由
      })
    }
  }else{
    next()
  }
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
